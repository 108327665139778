/* Core */
import { Store } from 'state/ContextStore';
import { observer } from 'mobx-react';
import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

/* MUI */
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { LinearProgress, ToggleButtonGroup, ToggleButton, Box, Avatar, FormControl, OutlinedInput, InputAdornment } from '@mui/material';
/* ICONS */
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
//
import AdvancedSelectLabel from './AdvancedSelectLabel';
/**  import { CopyCourseContext } from './context'; */
import { isSelected, isActive } from './advancedCourse.helper';

// eslint-disable-next-line import/no-cycle

/* SCSS */
import '../../scss/MenuList.scss';
// HOOKS
import useEwapi from '../../hooks/useEwapi';

/*
The primary purpose of this component is to provide users with the ability to select a package and
submenu for various actions (reusing, duplicating, or linking courses).
It offers filtering options and handles package and submenu selection events.
*/
const AdvancedCourseMenuList = ({ handleCopyActions, copyValues, profId }) => {
  const store = useContext(Store);
  const { t } = useTranslation('adminCourses');
  const theme = useTheme();
  // Hook fetch package list
  const url = `editor-package-list/${0}/${0}/json`;
  const { response, loading, error } = useEwapi(url, 'packages');

  // FILTERING
  // - search filter
  const [search, setSearch] = useState('');
  // - packageStatus => 0 = all, 1 = main
  const [prefPackage, setPrefPackage] = useState(1);
  const [filtered, setFiltered] = useState([]);
  const [open, setOpen] = useState(copyValues.packageNid);
  const [labelFilter, setLabelFilter] = useState(store.label);
  const { containerNid, packageNid } = store.routerStore.routerState.params;

  useEffect(() => {
    if (!response?.data) return;
    let filteredData = [];
    if (search.length) {
      filteredData = response.data.packages.filter((item) => item.package_title.toLowerCase().includes(search));
    }
    setFiltered(filteredData);
  }, [response?.data, search]);

  // SUBMENU ITEMS
  const subMenus = [
    { id: 2, name: 'Online', value: 1, class: 'online', icon: <CheckIcon />, routeName: 'adminOnlineCourses' },
    { id: 3, name: 'Offline', value: 2, class: 'offline', icon: <CloudOffIcon />, routeName: 'adminOfflineCourses' }
  ];

  // EVENT HANDLERS
  const handleClickOpen = (item) => setOpen(item);

  const handlePrefPackages = (event, value) => setPrefPackage(value);

  const isCurrentCid = (value) => (value.toString() === containerNid.toString() ? 'current' : '');

  const handleMenuSelect = (_package, _subMenu) => () => {
    // EVENTS
    handleCopyActions({
      ...copyValues,
      package: _package.package_title,
      fromContainerNid: _package.course_container_id,
      categoryUuid: '',
      course: 'onbekend',
      courseNid: '',
      courseUuid: '',
      subMenuId: _package.package_id,
      subMenuItem: _subMenu.name,
      listType: _subMenu.value,
      course_nid: 0
    });
  };

  // TRANSLATIONS
  const txtNoSelAction = t('copyDialog.selPackage.noAction');
  const btnMain = t('copyDialog.selPackage.main');
  const btnAll = t('copyDialog.selPackage.all');
  const filterPlaceholder = t('copyDialog.selPackage.filter');

  const sxBtnGroup = {
    m: 1,
    display: 'flex',
    '.MuiToggleButton-root': { lineHeight: '15px', textTransform: 'capitalize', flexGrow: 1 },
    '.Mui-selected': { background: theme.palette.primary.main, color: 'white' }
  };
  const sxCurCid = { '.current .MuiListItemText-primary': { fontStyle: 'italic' } };

  // RENDERING
  if (!copyValues.action) return <p>{txtNoSelAction}</p>;
  // - based on hook response
  if (loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }
  if (error) return <p>error...</p>;

  /*
  Action Id:
  1 = Reuse
  2 = Duplicate
  3 = Link
  */

  // if current package is
  // - microlearning: then only show other microlearning packages (excluded the current package)
  // - non microlearning package: show all packages (excluded the current package)
  // To check if the current package is a microlearning package, we need to get the current package from the response.
  const curPackageIsMicroLearning = response?.data?.packages.find((item) => item.package_id === +packageNid).microlearning;
  // - filter the packages based on the current package microlearning status
  const menuList = curPackageIsMicroLearning
    ? response?.data?.packages.filter((item) => item.microlearning === curPackageIsMicroLearning)
    : response?.data?.packages;

  const menuListItems = menuList.filter((item) => item.label === labelFilter);
  const showList = search.length ? filtered : menuListItems;

  let otherContainers;
  if (copyValues.actionId === 3) {
    otherContainers = showList.filter(
      (item) =>
        item.profession_id === profId &&
        item.course_container_id.toString() !== containerNid.toString() &&
        !item.package_title.includes('(not') &&
        item.preferred_package === prefPackage
    );
  } else {
    otherContainers = showList.filter(
      (item) =>
        item.course_container_id.toString() !== containerNid.toString() &&
        item.preferred_package === prefPackage &&
        !item.package_title.includes('(not')
    );
  }

  otherContainers.sort((a, b) => a.package_title.localeCompare(b.package_title));

  return (
    <Box className="package_list_container">
      <Box>
        <AdvancedSelectLabel menuList={menuList} setLabelFilter={setLabelFilter} />
        <ToggleButtonGroup
          sx={sxBtnGroup}
          size="small"
          value={prefPackage}
          exclusive
          onChange={handlePrefPackages}
          aria-label="packageList"
        >
          <ToggleButton value={1}>{btnMain}</ToggleButton>
          <ToggleButton value={0}>{btnAll}</ToggleButton>
        </ToggleButtonGroup>
        <FormControl sx={{ m: 1 }} variant="outlined">
          <OutlinedInput
            id="filter_packages"
            placeholder={filterPlaceholder}
            type="text"
            name="search"
            autoComplete="off"
            value={search}
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <Box sx={{ margin: '0px', fontSize: '12px', textAlign: 'center' }} component="p" className="result-count">
          {/* Showing: {search ? filtered.length : menuListItems.length} item(s) */}
          Showing: {otherContainers.length} item(s)
        </Box>
      </Box>
      <Box className="list_packages" sx={sxCurCid}>
        <List dense component="nav" aria-labelledby="nested-list" className="menu-list-root">
          {menuListItems &&
            otherContainers.map((item) => (
              <Box
                className={`package__list ${isSelected(item, open)} ${isCurrentCid(item.course_container_id)}`}
                key={item.package_id}
                sx={{
                  borderBottom: '1px solid #c0c0c057',
                  '&.selected .MuiAvatar-root': { backgroundColor: theme.palette.primary.main, color: 'white' }
                }}
              >
                <ListItemButton key={item.package_id} onClick={() => handleClickOpen(item.package_id)}>
                  <ListItemIcon>
                    <Avatar
                      // sx={{ fontSize: '17px', background: `${setColor(item, copyValues)[0]}`, color: `${setColor(item, copyValues)[1]}` }}
                      sx={{ fontSize: '17px' }}
                    >
                      {item.package_title.substring(0, 2).toUpperCase()}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${item.package_title}`}
                    secondary={`${item.package_id} - ${item.course_container_id} - ${item.profession_id}`}
                  />
                  {open === item.package_id ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open === item.package_id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding sx={{ marginLeft: '15px', marginRight: '15px' }}>
                    {subMenus.map((subMenuItem) => (
                      <ListItemButton
                        key={subMenuItem.id}
                        sx={{
                          backgroundColor: `${isActive(item, subMenuItem, copyValues) ? theme.palette.primary.light : 'transparent'} `,
                          color: `${isActive(item, subMenuItem, copyValues) && 'white'}`
                        }}
                        button="true"
                        className={`list-item-nested package__list_submenu subMenu_${isActive(item, subMenuItem, copyValues)}`}
                        onClick={handleMenuSelect(item, subMenuItem)}
                      >
                        <ListItemIcon> {subMenuItem.icon} </ListItemIcon>
                        <ListItemText primary={subMenuItem.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Box>
            ))}
        </List>
      </Box>
    </Box>
  );
};

AdvancedCourseMenuList.propTypes = {
  handleCopyActions: PropTypes.func,
  copyValues: PropTypes.object,
  profId: PropTypes.number
};

export default observer(AdvancedCourseMenuList);
