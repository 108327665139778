/* eslint-disable react/no-danger */
import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Box, LinearProgress } from '@mui/material';
import EditWidgetButton from 'pages/play/questions/EditWidgetButton';
import { useTranslation } from 'react-i18next';
import { rawHTML } from 'helpers/Helpers';
import { seededShuffle } from 'pages/play/questions/lib/seededShuffle';
import useWidget from './useWidget';

const useStyles = makeStyles((theme) => ({
  group: {
    margin: `${theme.spacing}px 0`,
    paddingBottom: '15px'
  }
}));
const bg = {
  false: 'darkred',
  true: 'darkgreen'
};

const CheckQuestion = (props) => {
  const { store, widgetCode } = props;
  const { t } = useTranslation('widget');
  const { question, widget, allowedTries, PlayWidget, answerDisabled } = useWidget(props);
  //
  const [isValidated, setIsValidated] = useState(false);
  const classes = useStyles();
  //
  const dontShowBg = window.store?.getFeedback1() || false;
  const rotatingAnswers = store.lessonPlay.lesson.attributes.rotating_answers;

  const widgetAttr = widget.attributes;

  const [values, setValues] = useState([]);

  const touched = useRef(!!question.last_response);

  const canSeeAnswers = store?.canSeeAnswers;

  useEffect(() => {
    const originalOptions = widgetAttr.options.map((option, index) => ({
      originalOption: option,
      apiIndex: index,
      label: option?.label
    }));

    const lastResponseResult = question.last_response
      ? question.last_response.split(',').map((value) => value === '1')
      : Array(widgetAttr.options.length).fill(false);

    const parsedOptions = originalOptions.map((option) => ({
      ...option,

      lastChecked: lastResponseResult[option.apiIndex],
      checked: lastResponseResult[option.apiIndex]
    }));

    const createSeed = () => {
      const seedLessonResult = parseInt(store.lessonPlay.lesson_result.id.replace(/[^\d]/g, ''), 10);
      const seedWidgetId = parseInt(widget.id.replace(/[^\d]/g, ''), 10);
      return seedLessonResult + seedWidgetId;
    };

    const shuffledOptions = rotatingAnswers ? seededShuffle(parsedOptions, createSeed()) : parsedOptions;

    setValues(shuffledOptions);
  }, [
    question.last_response,
    question.tries,
    rotatingAnswers,
    store.lessonPlay.lesson_result.id,
    widget.id,
    widgetAttr.options,
    widgetCode
  ]);

  const validateAnswer = () => {
    if (!values.map((option) => option.checked).includes(true)) {
      return false;
    }

    const userIsIncorrectAnswers = values
      .map((option) => ({ ...option, userIsCorrect: option.originalOption.correct === option.checked }))
      .filter((option) => !option.userIsCorrect);

    const responseValues = values
      .sort((a, b) => a.apiIndex - b.apiIndex)
      .map((option) => (option.checked ? '1' : '0'))
      .join(',');

    setIsValidated(true);
    return { value: responseValues, correct: userIsIncorrectAnswers.length < 1 };
  };

  const handleChange = (option) => {
    const originalValuesCopy = [...values];
    const listIndex = originalValuesCopy.findIndex((originalOption) => originalOption.apiIndex === option.apiIndex);

    originalValuesCopy[listIndex] = { ...option, checked: !option.checked };

    setValues(originalValuesCopy);
  };

  const checkOptions = () => {
    const disabled = answerDisabled || question.tries >= allowedTries || question.last_correct;

    return values.map((option) => {
      const { lastChecked } = option;
      const isChecked = option.checked;
      const showColor = isChecked && lastChecked;
      const getColor = showColor && option.originalOption.correct ? 'success' : 'error';
      const setColor = showColor ? getColor : 'default';
      return (
        <FormControlLabel
          className={isValidated && question.last_response && canSeeAnswers && isChecked ? getColor : 'not_set'}
          sx={{
            '& p:first-of-type': { marginTop: 0 },
            '& p:last-of-type': { marginBottom: 0 }
          }}
          key={option.apiIndex}
          disabled={disabled}
          control={
            <Checkbox
              onChange={() => handleChange(option)}
              value={option.originalOption.value}
              checked={option.checked}
              color={setColor}
              disabled={disabled}
              sx={{
                '&.Mui-checked': {
                  // eslint-disable-next-line no-nested-ternary
                  color: isValidated && question.last_response ? (dontShowBg ? 'primary' : bg[option.correct]) : 'primary'
                }
              }}
            />
          }
          label={
            <div className="label_check_option" style={{ color: canSeeAnswers && option.originalOption.correct ? 'green' : 'inherit' }}>
              {rawHTML(option.label)}
            </div>
          }
        />
      );
    });
  };

  // show feedback after an answer
  const showFeedback = () => {
    if (!question.tries) return null;

    return (
      <div className="feedback_block" style={{ width: '100%' }}>
        {!question.last_correct && <b className="wrong-answer">{t('Incorrect')}</b>}
        {question.last_correct && <b className="correct-answer">{t('Correct')}</b>}

        {/* 1. show specific feedback after 1st try */}
        {showSpecificFeedback()}

        {/* 2. show correct feedback / answer  */}
        {showCorrectFeedback()}

        {/* 2. show globalfeedBack after 2nd try */}
        {showGlobalFeedback()}

        {/* Text to try again afyer 1st attempt */}
        {question.tries < allowedTries && !question.last_correct && (
          <div className="retry" dangerouslySetInnerHTML={{ __html: t('Try again') }} />
        )}
      </div>
    );
  };

  function showGlobalFeedback() {
    if ((question.tries < allowedTries && !question.last_correct) || !widgetAttr.feedback) return null;
    /*  globalFeedback => widget.attributes.feedback 
      - shouldn't be used/filled for radio and check questions
    */
    // workaround when feedback is filled but only contains <p></p> (default from editor widget) */
    if (widgetAttr?.feedback.length < 15) return null;
    // Any othercase the feedback is shown as italic text
    return (
      <Box>
        <div style={{ fontStyle: 'italic' }}>{rawHTML(widgetAttr.feedback)}</div>
      </Box>
    );
  }

  function showSpecificFeedback() {
    const specificFeedback = values.reduce((html, option, index) => {
      if (option.lastChecked) {
        html.push(
          <Box className="feedback" key={index}>
            {rawHTML(option.originalOption.feedback)}
          </Box>
        );
      }
      return html;
    }, []);
    return <>{specificFeedback}</>;
  }

  // On correct answer or after max_retries show correct feedback (works for multiple question types)
  function showCorrectFeedback() {
    if (question.tries < allowedTries || question.last_correct) return null;

    const correctFeedback = values.reduce((html, option, index) => {
      if (option.originalOption.correct) {
        html.push(<Box key={index}>{rawHTML(option.originalOption.feedback)}</Box>);
      }
      return html;
    }, []);

    return (
      <>
        <b>{t('feedback')}</b>
        {correctFeedback}
      </>
    );
  }

  return (
    <>
      <EditWidgetButton store={store} action="edit" id={widgetCode} />
      {store.lessonPlay?.isSaving === widgetCode && <LinearProgress sx={{ position: 'absolute', top: '0', left: '0', width: '100%' }} />}
      <PlayWidget touched={touched.current} validateAnswer={validateAnswer} showFeedback={showFeedback}>
        <FormGroup className={`${classes.group} testingcheck`}>{checkOptions()}</FormGroup>
      </PlayWidget>
    </>
  );
};

CheckQuestion.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  widget: PropTypes.object.isRequired,
  widgetCode: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  question: PropTypes.object,
  store: PropTypes.object
};

export default observer(CheckQuestion);
