import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
// MUI
import { Grid, DialogContent, Box, Backdrop, CircularProgress, Stack } from '@mui/material';
/* Custom RHF components */
import { Form } from 'components/form';
import { MyTextField, MySelect, Editor } from 'components/form/RHF';
import FormActionBar from './FormActionBar';
import FormDefaultWidgetsAttributes from './FormDefaultWidgetsAttributes';
// helpers
import {
  numericalProps as numProps,
  textFieldProps as txtProps,
  toggleDialogEditWidget,
  ToastWidgetSubmit,
  defaultNumericalQuestionWidgetData,
  RadioSchema
} from './formWidgets.helper';
//

const FormNumericalQuestion = () => {
  const store = useStore();
  const { t } = useTranslation('editCourseForm', { keyPrefix: 'quickWidgetEditor' });
  const [saving, setSaving] = useState(false);

  const widget = store.courseEdit.setEditWidget;
  const widgetData = JSON.parse(store.courseEdit.setEditWidget.attributes.widget_data);
  const setWidgetData = { ...defaultNumericalQuestionWidgetData, ...widgetData };
  const defaultValues = { ...widget, attributes: { ...widget.attributes, widget_data: setWidgetData } };
  defaultValues.attributes.widget_type = 'Numerical Question';

  const options = 'attributes.widget_data.options';
  const selItems = [
    { name: 'slider', value: 'slider' },
    { name: 'input', value: 'input' }
  ];

  // TOAST NOTIFICATIONS ________________________________________________
  const toast = ToastWidgetSubmit();

  // ON SUBMIT / HANDLE SUBMIT __________________________________________
  const handleFormSubmit = async (newWidget) => {
    setSaving(true);
    try {
      await store.courseEdit.patchPostWidget(newWidget);
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
      setSaving(false);
      toggleDialogEditWidget(store);
    } catch (error) {
      setSaving(false);
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
      toggleDialogEditWidget(store);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} initialValues={defaultValues} validationSchema={RadioSchema}>
      {({ control, errors, dirty, reset, setValue }) => (
        <>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={saving}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent sx={{ padding: '0px 24px' }}>
            {/* top */}
            <Grid container spacing={1} sx={sxGrid}>
              <Grid item xs={10}>
                <Box sx={sxFlexColumnGap}>
                  <MyTextField
                    {...txtProps}
                    label={t('title')}
                    placeHolder={t('titlePlaceholder')}
                    name="attributes.title"
                    error={errors?.attributes?.title}
                    helperText={errors?.attributes?.title && errors.attributes.title?.message}
                    control={control}
                  />
                  <Box className="container-editor">
                    <Box>
                      <Editor field={{ name: 'attributes.widget_data.question' }} form={{ setValue, control }} label={t('question')} />
                      <Editor field={{ name: 'attributes.widget_data.feedback' }} form={{ setValue, control }} label={t('feedback')} />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ padding: '15px 0px', paddingTop: '30px', paddingLeft: '15px' }}>
                    <Stack sx={{ columnGap: '4px' }} direction="row" justifyContent="center" alignItems="center" spacing={1}>
                      <MyTextField {...numProps} name={`${options}.value`} control={control} label={`${t('start')} ${t('value')}`} />
                      <MyTextField {...numProps} name={`${options}.valueEnd`} control={control} label={`${t('end')} ${t('value')}`} />
                      <MyTextField {...numProps} name={`${options}.sliderMin`} control={control} label={`${t('slider')} ${t('min')}`} />
                      <MyTextField {...numProps} name={`${options}.sliderMax`} control={control} label={`${t('slider')} ${t('max')}`} />
                      <MyTextField {...numProps} name={`${options}.sliderStep`} control={control} label={`${t('slider')} ${t('step')}`} />
                      <MyTextField {...txtProps} name={`${options}.unit`} control={control} label={t('unit')} />
                      <MySelect name={`${options}.type`} items={selItems} fullWidth sx={sxSelect} control={control} label={t('type')} />
                    </Stack>
                  </Box>
                  <Box sx={{ ...sxFlexColumnGap, paddingLeft: '15px' }}>
                    <Box className="container-editor">
                      <Box>
                        <Editor field={{ name: `${options}.feedbackCorrect` }} form={{ setValue, control }} label={t('feedbackCorrect')} />
                        <Editor field={{ name: `${options}.feedbackMore` }} form={{ setValue, control }} label={t('feedbackMore')} />
                        <Editor field={{ name: `${options}.feedbackLess` }} form={{ setValue, control }} label={t('feedbackLess')} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <FormDefaultWidgetsAttributes control={control} />
              </Grid>
            </Grid>
          </DialogContent>
          <FormActionBar reset={reset} dirty={dirty} defaultValues={defaultValues} saving={saving} />
        </>
      )}
    </Form>
  );
};

export default observer(FormNumericalQuestion);

const sxFlexColumnGap = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px'
};
const sxGrid = {
  '.MuiFormControl-root': { margin: '0px' },
  '.MuiGrid-item': { paddingTop: '0px' }
};

const sxSelect = { '& > div': { padding: '10px' } };
