/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
// MUI
import { Grid, DialogContent, Box, Backdrop, CircularProgress } from '@mui/material';
/* Custom RHF components */
import { Form } from 'components/form';
import { MyTextField, Editor } from 'components/form/RHF';
//
import FormMatchOptions from './FormMatchOptions';
import FormActionBar from './FormActionBar';
import FormDefaultWidgetsAttributes from './FormDefaultWidgetsAttributes';
import {
  textFieldProps,
  toggleDialogEditWidget,
  ToastWidgetSubmit,
  defaultMatchQuestionWidgetData,
  RadioSchema
} from './formWidgets.helper';

const FormMatchQuestion = ({ optionType }) => {
  const store = useStore();
  const { t } = useTranslation('editCourseForm', { keyPrefix: 'quickWidgetEditor' });
  const [saving, setSaving] = useState(false);
  const widget = store.courseEdit.setEditWidget;

  // SETTING WIDGET DATA _______________________________________________
  const widgetData = JSON.parse(store.courseEdit.setEditWidget.attributes.widget_data);
  const setWidgetData = { ...defaultMatchQuestionWidgetData, ...widgetData };
  const defaultValues = { ...widget, attributes: { ...widget.attributes, widget_data: setWidgetData } };

  const _widgetData = 'attributes.widget_data';

  // SET CORRECT WIDGET TYPE
  defaultValues.attributes.widget_type = widget.attributes.widget_type.toLowerCase().includes('match') ? 'Match Question' : 'Sort Question';

  // TOAST NOTIFICATIONS ________________________________________________
  const toast = ToastWidgetSubmit();

  // ON SUBMIT / HANDLE SUBMIT __________________________________________
  const handleFormSubmit = async (newWidget) => {
    setSaving(true);
    try {
      await store.courseEdit.patchPostWidget(newWidget);
      store.courseEdit.setToasts({ ...toast.success, ...toast.default });
      setSaving(false);
      toggleDialogEditWidget(store);
    } catch (error) {
      setSaving(false);
      store.courseEdit.setToasts({ ...toast.error, ...toast.default });
      toggleDialogEditWidget(store);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit} initialValues={defaultValues} validationSchema={RadioSchema}>
      {({ control, errors, dirty, reset, setValue, getValues }) => (
        <>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={saving}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent sx={{ padding: '0px 24px' }}>
            {/* top */}
            <Grid container spacing={1} sx={sxGrid}>
              <Grid item xs={10}>
                <Box className="widget-question" sx={sxFlexColumnGap}>
                  <MyTextField
                    {...textFieldProps}
                    label={t('title')}
                    placeholder={t('titlePlaceholder')}
                    name="attributes.title"
                    control={control}
                    error={errors?.attributes?.title}
                    helperText={errors?.attributes?.title?.message}
                  />
                  <Box className="container-editor">
                    <Editor field={{ name: `${_widgetData}.question` }} form={{ setValue, control }} label={t('question')} />
                    <Editor field={{ name: `${_widgetData}.feedbackFalse` }} form={{ setValue, control }} label={t('feedbackFalse')} />
                    <Editor field={{ name: `${_widgetData}.feedbackCorrect` }} form={{ setValue, control }} label={t('feedbackCorrect')} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <FormDefaultWidgetsAttributes control={control} />
              </Grid>
            </Grid>
            {/* bottom */}
            <FormMatchOptions
              optionType={optionType || 'radio'}
              control={control}
              errors={errors}
              dirty={dirty}
              reset={reset}
              defaultValues={defaultValues}
              setValue={setValue}
              getValues={getValues}
            />
          </DialogContent>
          <FormActionBar reset={reset} dirty={dirty} defaultValues={defaultValues} saving={saving} />
        </>
      )}
    </Form>
  );
};

FormMatchQuestion.propTypes = {
  optionType: PropTypes.string
};

export default observer(FormMatchQuestion);

// custom sx ___________________________________________________
const sxGrid = {
  '.MuiFormControl-root': { margin: '0px' },
  '.MuiGrid-item': { paddingTop: '0px' }
};
//
const sxFlexColumnGap = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px'
};
