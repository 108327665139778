import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useState, useEffect, Children, cloneElement, useCallback, useRef } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const CarouselItem = ({ children, className }) => (
  <div className={className ? `carousel-item ${className}` : 'carousel-item'}>{children}</div>
);

CarouselItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const Slider = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const swipeState = useRef({ x: 0 });

  const handleMouseDown = (e) => {
    swipeState.current.x = e.screenX;
  };

  const handleClick = (e) => {
    const delta = Math.abs(e.screenX - swipeState.current.x);
    if (delta > 10) {
      e.preventDefault();
    }
  };

  const updateIndex = useCallback(
    (newIndex) => {
      const count = Children.count(children);
      if (newIndex < 0) {
        newIndex = count - 1;
      } else if (newIndex >= count) {
        newIndex = 0;
      }
      setActiveIndex(newIndex);
    },
    [children]
  ); // Depend on children as it defines the total number of slides

  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(() => {
        updateIndex(activeIndex + 1);
      }, 4000); // Changes slide every 2 seconds
    }

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [activeIndex, isPaused, updateIndex]); // Now includes updateIndex as a dependency

  return (
    <Box className="gallery" onMouseEnter={() => setIsPaused(true)} onMouseLeave={() => setIsPaused(false)}>
      <SwipeableViews
        index={activeIndex}
        onChangeIndex={updateIndex}
        enableMouseEvents
        onMouseDown={handleMouseDown}
        onClick={handleClick}
        threshold={10}
      >
        {Children.map(children, (child, index) => (
          <Box
            key={index}
            className={`children_box children_box_${index}`}
            sx={{ backgroundImage: child.props.img ? `url(${child.props.img})` : 'none' }}
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
            {cloneElement(child)}
            <Box className="indicators_box" sx={{ opacity: Children.count(children) > 1 ? 1 : 0 }}>
              {Children.map(children, (indicatorChild, buttonIndex) => (
                <Button
                  key={buttonIndex}
                  className={buttonIndex === activeIndex ? 'active' : ''}
                  onClick={() => {
                    updateIndex(buttonIndex);
                    setIsPaused(true); // Keep paused state when an indicator is clicked
                  }}
                >
                  <FiberManualRecordIcon fontSize="small" sx={{ color: buttonIndex === activeIndex ? 'primary.main' : 'white' }} />
                </Button>
              ))}
            </Box>
          </Box>
        ))}
      </SwipeableViews>
    </Box>
  );
};

Slider.propTypes = {
  children: PropTypes.node
};

export default Slider;
