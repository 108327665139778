/* eslint-disable consistent-return */
/* Core */
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { useStore } from 'hooks';

// MUI
import { Grid, Divider, Box, Chip, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
/* Custom RHF components */
import { MyCheckbox, MySelect, MyTextField } from 'components/form/RHF';
import MyDatePicker from 'components/form/RHF/DatePicker/MyDatepicker';
import MyAutocompleteTaxonomy from 'pages/adminCourses/components/RHF/MyAutocompleteTaxonomy';
import MyMultiSelect from 'pages/adminCourses/components/RHF/MyMultiSelect';
import MyNumberfield from 'pages/adminCourses/components/RHF/MyNumberfield';
import MyAutocomplete from 'components/form/RHF/Autocomplete/MyAutocomplete';
import UploadButton from 'pages/adminCourses/components/editCourse/UploadButton';

/*
In summary, the CourseEditForm component is used to create an admin-friendly form for editing course details, 
including online/offline status, course status, points, validity period, accreditation code, categories, competences, and keywords. 
It uses a controlled form approach and displays localized labels for various form elements.
*/
const CourseEditForm = ({ control, isDisabled, ...restProps }) => {
  const { t } = useTranslation('editCourseForm');
  const store = useStore();
  const { errors, setError, clearErrors } = restProps;

  const status = [
    { name: t('editCourseForm.status.new'), value: 'New' },
    { name: t('editCourseForm.status.default'), value: 'Default' },
    { name: t('editCourseForm.status.update'), value: 'Update' },
    { name: 'Update (manual)', value: 'Update (manual)' },
    { name: 'None', value: 'None' }
  ];
  const fieldExtraPointsDesc = ['accountancy'];

  const sxNoPM = { margin: '0px', padding: '0px 10px' };
  //
  const watchStartDate = !!useWatch({ control, name: 'course.attributes.field_valid_period.value' });
  const watchEndDate = !!useWatch({ control, name: 'course.attributes.field_valid_period.end_value' });
  const watchStatus = useWatch({ control, name: 'course.attributes.status' });
  const watchOnline = useWatch({ control, name: 'course.attributes.online' }) === '1';
  const isPublished = watchStatus && watchOnline;
  const areDatesSet = watchStartDate && watchEndDate;
  const is1daySet = (watchStartDate && !watchEndDate) || (!watchStartDate && watchEndDate);

  useEffect(() => {
    const setRequired = { types: { required: 'This is required' } };
    if (isPublished && !areDatesSet) {
      setError('course.attributes.field_valid_period.end_value', setRequired);
      setError('course.attributes.field_valid_period.value', setRequired);
    } else if (isPublished && areDatesSet) {
      clearErrors(['course.attributes.field_valid_period.end_value', 'course.attributes.field_valid_period.value']);
    }
    if (!isPublished && is1daySet) {
      if (watchStartDate && !watchEndDate) setError('course.attributes.field_valid_period.end_value', setRequired);
      if (!watchStartDate && watchEndDate) setError('course.attributes.field_valid_period.value', setRequired);
    }
    if (!isPublished && !is1daySet) {
      clearErrors(['course.attributes.field_valid_period.end_value', 'course.attributes.field_valid_period.value']);
    }
  }, [watchStartDate, watchEndDate, setError, errors, clearErrors, watchStatus, watchOnline, isPublished, areDatesSet, is1daySet]);

  return (
    <Box className="box-right" sx={{ flexGrow: 1 }}>
      <Grid className="grid6" container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginLeft: '0px' }}>
        <Grid item xs={12} sx={sxNoPM}>
          <Box display="flex" flexDirection="column" paddingLeft="10px">
            <MyCheckbox
              color="primary"
              control={control}
              name="course.attributes.status"
              label={t('editCourseForm.published')}
              disabled={isDisabled('course') || restProps.values.course.attributes.status}
            />
          </Box>
        </Grid>
        <Grid item sx={(sxNoPM, { paddingTop: '0px!important', marginTop: '0px' })} xs={12} className="no-border">
          <MySelect
            disabled={isDisabled('course')}
            control={control}
            name="course.attributes.online"
            items={[
              {
                name: (
                  <Chip
                    icon={<CheckIcon />}
                    sx={{ width: '100%', height: '30px', color: 'white' }}
                    label={t('editCourseForm.online')}
                    color="success"
                  />
                ),
                value: '1'
              },
              {
                name: (
                  <Chip icon={<ClearIcon />} sx={{ width: '100%', height: '30px' }} label={t('editCourseForm.offline')} color="error" />
                ),
                value: '0'
              }
            ]}
          />
        </Grid>
        <Grid item xs={12} sx={sxNoPM}>
          <MySelect
            disabled={isDisabled('course')}
            control={control}
            name="course.attributes.course_status"
            label="Course status"
            items={status}
            helperText={errors?.course?.attributes?.course_status?.message}
          />
        </Grid>
        <Grid item xs={12} sx={sxNoPM}>
          <Box display="flex" flexDirection="column" paddingLeft="10px">
            <MyCheckbox
              control={control}
              name="course.attributes.expected"
              label={t('editCourseForm.expected')}
              disabled={isDisabled('course')}
            />
            <Typography variant="p" fontSize="12px">
              {t('editCourseForm.expectedInfo')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" paddingLeft="10px" sx={{ display: 'none' }}>
            <MyCheckbox
              control={control}
              name="course.attributes.allow_dry_run"
              label={t('editCourseForm.dryRun')}
              disabled={isDisabled('course')}
            />
            <Typography variant="p" fontSize="12px">
              {t('editCourseForm.dryRunInfo')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid className="grid6-6" container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginLeft: '0px' }}>
        <Grid item xs={12}>
          <Divider>Special access</Divider>
          <MyMultiSelect
            items={store.courseEdit.specialAccessMenu}
            name="course.relationships.special_access"
            label="Special access"
            disabled={isDisabled('course')}
            control={control}
          />
          <Divider>{t('sidebar.points')}</Divider>
        </Grid>
        <Grid item xs={6}>
          <MyNumberfield
            margin="normal"
            name="course.attributes.field_points"
            label={store.courseEdit?.accreditation?.attributes?.field_accr_unit_plural || t('editCourseForm.points')}
            fullWidth
            control={control}
            disabled={isDisabled('course')}
            error={!!errors?.course?.attributes?.field_points}
            helperText={errors?.course?.attributes?.field_points?.message}
          />
        </Grid>
        <Grid item xs={6}>
          {store.courseEdit?.accreditation?.attributes?.extra_points_unit && (
            <MyNumberfield
              margin="normal"
              name="course.attributes.field_extra_points"
              label={store.courseEdit?.accreditation?.attributes?.extra_points_unit || t('editCourseForm.extraPoints')}
              type="text"
              disabled={isDisabled('course')}
              control={control}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {fieldExtraPointsDesc.includes(store?.params?.package) ? (
            <MyTextField
              margin="normal"
              name="course.attributes.field_extra_points_desc"
              label={t('editCourseForm.extraPointsDesc')}
              type="text"
              disabled={isDisabled('course')}
              control={control}
            />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider>{t('sidebar.dateValid')}</Divider>
        </Grid>

        <Grid item xs={6}>
          <MyDatePicker
            inputFormat="dd/MM/yyyy"
            name="course.attributes.field_valid_period.value"
            label={t('editCourseForm.from')}
            size="small"
            disabled={isDisabled('course')}
            control={control}
            error={(!watchStartDate && watchEndDate) || (isPublished && !areDatesSet)}
            helperText={errors?.course?.attributes?.field_valid_period?.value?.message}
          />
        </Grid>
        <Grid item xs={6}>
          <MyDatePicker
            disabled={isDisabled('course')}
            inputFormat="dd/MM/yyyy"
            name="course.attributes.field_valid_period.end_value"
            label={t('editCourseForm.till')}
            control={control}
            error={(!watchEndDate && watchStartDate) || (isPublished && !areDatesSet)}
            helperText={errors?.course?.attributes?.field_valid_period?.end_value?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider>{t('sidebar.accreditation')}</Divider>
        </Grid>
        <Grid item xs={12}>
          <MyCheckbox
            color="primary"
            control={control}
            name="course.attributes.field_skip_evaluation"
            label={t('editCourseForm.skipEvaluation', { defaultValue: 'Evaluatie overslaan' })}
          />
          <MyTextField
            disabled={isDisabled('course')}
            margin="normal"
            name="course.attributes.field_accreditation_code"
            label={t('editCourseForm.accreditationCode')}
            type="text"
            control={control}
            error={!!errors?.course?.attributes?.field_accreditation_code}
            helperText={errors?.course?.attributes?.field_accreditation_code?.message}
          />
        </Grid>
      </Grid>

      <Divider>{t('sidebar.catComp')}</Divider>
      <Grid item sx={{ margin: '20px 0px' }} className="tags_category">
        <MyAutocompleteTaxonomy
          disabled={isDisabled('course')}
          control={control}
          name="course.relationships.course_category"
          label={t('editCourseForm.categories')}
          error={!!errors?.course?.relationships?.field_course_category}
          helperText={errors?.course?.relationships?.field_course_category?.message}
        />
      </Grid>
      <Grid item sx={{ margin: '20px 0px' }} className="tags_competence">
        <MyAutocompleteTaxonomy
          disabled={isDisabled('course')}
          control={control}
          name="course.relationships.course_category2"
          label={t('editCourseForm.competence')}
        />
      </Grid>
      <Divider>{t('sidebar.searchTags')}</Divider>
      <Grid item>
        <MyAutocomplete
          disabled={isDisabled('lessonContainer')}
          limitTags={3}
          control={control}
          color="primary"
          label={t('editCourseForm.keyword')}
          name="lessonContainer.attributes.field_keywords"
        />
      </Grid>
      {store.canAddAnswerPdfToCourse && (
        <>
          <Divider>{t('sidebar.uploadPdf')}</Divider>
          <Grid item>
            <UploadButton />
          </Grid>
        </>
      )}
    </Box>
    // </Grid>
  );
};

CourseEditForm.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  isDisabled: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func
};

export default observer(CourseEditForm);
