/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useFieldArray } from 'react-hook-form';
// MUI
import { Grid, Box, Button, IconButton, Stack } from '@mui/material';
/* ICONS */
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
/* Custom RHF components */
import { Editor, MyTextField } from 'components/form/RHF';
import { numericalProps } from './formWidgets.helper';

const FormMatchMatches = ({ setValue, control, optionsIndex }) => {
  const { fields, append, remove } = useFieldArray({ control, name: `attributes.widget_data.options[${optionsIndex}].matches` });

  return (
    <Grid container spacing={0} sx={sxGrid}>
      <Grid sx={{ paddingTop: '0px', marginTop: '-10px', marginLeft: '-30px' }} item xs={12} container>
        <Button
          size="small"
          variant="contained"
          startIcon={<AddIcon size="small" />}
          onClick={() => {
            append({ answer: `item`, nr: `${optionsIndex + 1}` });
          }}
          color="warning"
          disableElevation
        >
          Item
        </Button>
      </Grid>
      {fields.map((field, index) => (
        <li key={field.id} style={{ width: '100%' }}>
          <Box sx={{ width: '100%', display: 'flex' }}>
            <Stack spacing={2} direction="row" alignItems="center" sx={{ flex: 1 }}>
              <Box sx={{ display: 'flex', flex: 1 }}>
                <Box className="container-editor" style={{ width: '100%' }}>
                  <Box>
                    <Editor
                      disabled={false}
                      label={`Item in container ${optionsIndex + 1}`}
                      field={{ name: `attributes.widget_data.options[${optionsIndex}].matches[${index}].answer` }}
                      form={{ setValue, control }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="">
                <Box>
                  <MyTextField
                    {...numericalProps}
                    type="tekst"
                    label="Nr"
                    name={`attributes.widget_data.options[${optionsIndex}].matches[${index}].nr`}
                    control={control}
                  />
                </Box>
              </Box>
              <IconButton aria-label="delete" size="medium" onClick={() => remove(index)}>
                <HighlightOffIcon fontSize="inherit" color="error" />
              </IconButton>
            </Stack>
          </Box>
        </li>
      ))}
    </Grid>
  );
};

FormMatchMatches.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
  optionsIndex: PropTypes.number
};

export default observer(FormMatchMatches);

// custom sx ________________________________________________________
const sxGrid = {
  '.MuiFormControl-root': { margin: '0px' },
  '.MuiGrid-item': { paddingTop: '0px' }
};
