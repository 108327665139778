/* eslint-disable camelcase */
/* Core */
import { Store } from 'state/ContextStore';
import { useContext, useEffect, useState, memo } from 'react';

/* MUI */
import { Box, Typography, Button, Chip, Skeleton } from '@mui/material';
import Slider, { CarouselItem } from 'components/common/Slider';
import { replaceSliderCssClass, minifyCss } from 'helpers/Helpers';
import Link from '@mui/material/Link';
import { DefaultBanner } from '../../../../helpers/profBanners.helper';

const drupalBaseUrl = process.env.REACT_APP_JSONAPI;

const Banner = () => {
  const store = useContext(Store);
  const [loading, setLoading] = useState(false);
  const selProductId = store.bannerProducts.length > 0 ? store.bannerProducts.join() : store.selectedProductId.toString();

  useEffect(() => {
    const fetchBannerData = async () => {
      setLoading(true);
      await store.fetchBanner(selProductId);
      setLoading(false);
    };

    fetchBannerData();
  }, [selProductId, store]);

  const getLinkAttributes = (url) => {
    if (!url) return null;

    const currentUrl = new URL(window.location.href);
    const targetUrl = new URL(url);

    return {
      href: currentUrl.hostname === targetUrl.hostname ? targetUrl.pathname + targetUrl.search + targetUrl.hash : targetUrl.toString(),
      target: currentUrl.hostname === targetUrl.hostname ? '_self' : '_blank',
      draggable: false
    };
  };

  return (
    <>
      {loading && <Skeleton animation="wave" variant="rectangular" className="banner loading" />}

      {!loading && (!store?.banners || store?.banners?.length === 0) && (
        <Box className="banner fallback">
          <Box width="100%">
            <img src={DefaultBanner()} alt="Banner" />
          </Box>
        </Box>
      )}

      {!loading && store?.banners && store?.banners?.length > 0 && (
        <Box className="banner">
          <Box width="100%">
            <Slider>
              {store.banners.map((banner, index) => {
                const { description, title, button_link, button_title } = banner.attributes;
                const image = banner.relationships.background_image.data;
                const bannerImage = image && image.attributes.uri.url;
                const imageUrl = `${drupalBaseUrl}${bannerImage}`;
                const customCss = banner.attributes?.field_custom_css || '';
                const chipLabel = banner.attributes?.chip || '';

                const colorScheme = {
                  title: banner.attributes?.title_color || 'primary',
                  button: banner.attributes?.button_color || 'primary',
                  chip: banner.attributes?.chip_color || 'primary'
                };

                const ContainerComponent = button_link ? Link : Box;

                return (
                  <CarouselItem key={index} img={imageUrl}>
                    <ContainerComponent {...getLinkAttributes(button_link)} className="carousel_container">
                      <Box>
                        {chipLabel && <Chip label={chipLabel} color={colorScheme.chip} className="bannerChip" />}
                        {title && (
                          <Typography variant="h1" fontSize="34px" fontWeight="700" color={colorScheme.title} className="bannerTitle">
                            {title}
                          </Typography>
                        )}
                        {description && (
                          <Typography fontSize="16px" fontWeight="500" color="black" className="bannerDescription">
                            {description}
                          </Typography>
                        )}
                        {button_title && (
                          <Button variant="contained" className="bannerButton" color={colorScheme.button}>
                            {button_title || 'Discover more'}
                          </Button>
                        )}
                      </Box>
                      <style>{replaceSliderCssClass(minifyCss(customCss), 'bannerContainer', `children_box_${index}`)}</style>
                    </ContainerComponent>
                  </CarouselItem>
                );
              })}
            </Slider>
          </Box>
        </Box>
      )}
    </>
  );
};

export default memo(Banner);
