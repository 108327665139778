/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useFieldArray } from 'react-hook-form';
// MUI
import { Grid, Box, Button } from '@mui/material';
/* ICONS */
import AddIcon from '@mui/icons-material/Add';
/* Custom RHF components */
import { MyTextField } from 'components/form/RHF';
import FormMatchMatches from './FormMatchMatches';

const FormMatchOptions = ({ setValue, control, ...restProps }) => {
  const { fields, append } = useFieldArray({ control, name: 'attributes.widget_data.options' });

  return (
    <Grid container spacing={1} sx={sxGrid}>
      <Grid item xs={12} container>
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => {
            append({
              label: 'Container',
              nr: fields.length + 1,
              matches: [{ answer: '', nr: (fields.length + 1).toString(), startPosition: 0 }]
            });
          }}
          color="primary"
        >
          Container
        </Button>
      </Grid>

      {fields.map((option, index) => (
        <Grid item key={option.id} xs={12} container>
          <Box sx={{ width: '100%', display: 'flex' }}>
            <Box sx={{ flex: 1 }}>
              <Box>
                <Box sx={{ display: 'flex' }}>
                  <Box>{index + 1}</Box>
                  <MyTextField name={`attributes.widget_data.options[${index}].label`} label={`Container ${index + 1}`} control={control} />
                </Box>
                <ul style={{ listStyleType: 'none' }}>
                  <FormMatchMatches control={control} optionsIndex={index} setValue={setValue} {...restProps} />
                </ul>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

FormMatchOptions.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func
};

export default observer(FormMatchOptions);

// custom sx ________________________________________________________
const sxGrid = {
  '.MuiFormControl-root': { margin: '0px' },
  '.MuiGrid-item': { paddingTop: '0px' }
};
