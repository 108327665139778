import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const sxCustomQuickEditor = {
  '& .MuiDialog-paper': { borderRadius: '15px' },
  ' .ql-toolbar.ql-snow': { borderRadius: '4px 4px 0 0', padding: '0', background: '#9e9e9e29' },
  ' .ql-container.ql-snow': { borderRadius: '0 0 4px 4px' },
  ' .container-editor': { margin: '-5px' }
};

// _____ BUTTONS TO CREATE QUICK WIDGETS __________________________________
export const widgetButtons = {
  available: [
    { type: 'radio question', label: 'Radio' },
    { type: 'check question', label: 'Check' },
    { type: 'video widget', label: 'Video' },
    { type: 'open question', label: 'Open' },
    { type: 'html widget', label: 'Html' },
    { type: 'sort question', label: 'Sort' },
    { type: 'match question', label: 'Match' },
    { type: 'numerical question', label: 'Numerical' }
  ],
  disabled: ['wordcloud', 'poll', 'scorm'],
  props: { color: 'primary', variant: 'contained', size: 'small', disableElevation: true }
};

// _____ TOGGLE DIALOG EDIT WIDGET _________________________________
export const toggleDialogEditWidget = (store) => {
  store.courseEdit.setStoreValue('toggleDialogEditWidget', false);
};

// _____ TOAST MESSAGES FOR SAVE RESULT WIDGETS ___________________
export const ToastWidgetSubmit = () => {
  const { t } = useTranslation('editCourseForm', { keyPrefix: 'quickWidgetEditor' });
  return {
    success: { title: t('save.success.title'), content: t('save.success.message'), type: 'success' },
    error: { title: t('save.error.title'), content: t('save.error.message'), type: 'error' },
    default: { timeOut: 5000, variant: 'filled' }
  };
};

// DEFAULT FORM FIELDS PROPS
export const numericalProps = {
  fullWidth: true,
  margin: 'normal',
  type: 'number',
  inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
  InputLabelProps: { shrink: true }
};
export const textFieldProps = { fullWidth: true, margin: 'normal', type: 'text', InputLabelProps: { shrink: true } };

// _______ DEFAULT WIDGET_DATA (stringify when saving) for each widget __________
export const defaultVideoQuestionWidgetData = {
  question: '',
  feedback: '',
  options: { url: '' }
};
// DEFAULT WIDGET_DATA (stringify when saving)
export const defaultHtmlQuestionWidgetData = {
  question: '',
  feedback: '',
  options: { htmlData: '' }
};
// RADIO QUESTION
export const defaultRadioQuestionWidgetData = {
  question: '',
  feedback: '',
  feedbackCorrect: '',
  feedbackFalse: '',
  options: [
    { value: '1', label: 'antwoord 1', feedback: '', correct: true },
    { value: '2', label: 'antwoord 2', feedback: '', correct: false }
  ]
};
// MATCH QUESTION
export const defaultMatchQuestionWidgetData = {
  question: 'Match',
  feedbackCorrect: '',
  feedbackFalse: '',
  options: [
    {
      label: 'Container 1',
      nr: 1,
      matches: [{ answer: 'item in container 1', nr: '1' }]
    },
    {
      label: 'Container 2',
      nr: 2,
      matches: [{ answer: 'item in container 2', nr: '2' }]
    }
  ]
};
// SORT QUESTION
export const defaultSortQuestionWidgetData = {
  question: 'Sort',
  feedbackCorrect: '',
  feedbackFalse: '',
  options: [
    { label: 'label1', value: '2', startposition: 1 },
    { label: 'label2', value: '1', startposition: 2 },
    { label: 'label3', value: '3', startposition: 3 }
  ]
};
// OPEN QUESTION
export const defaultOpenQuestionWidgetData = {
  question: '',
  feedback: '',
  options: { type: 'Open' }
};

export const defaultNumericalQuestionWidgetData = {
  question: 'numerical question',
  feedback: '',
  options: {
    value: 2,
    valueEnd: 2,
    feedbackCorrect: 'correct',
    feedbackLess: 'less',
    feedbackMore: 'more',
    sliderMin: 50,
    sliderMax: 149,
    sliderStep: 1,
    unit: '',
    type: 'slider'
  }
};

// _________ DEFAULT Yup validation schemas for all quick edit widgets ___________
export const RadioSchema = Yup.object().shape({
  attributes: Yup.object({
    title: Yup.string().min(5, 'Title should be longer than 5 characters').required('Title is required')
  })
});
