/* Core */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { MyTextField } from 'components/form/RHF';
import { numericalProps, textFieldProps } from './formWidgets.helper';

const FormDefaultWidgetsAttributes = ({ control }) => {
  const { t } = useTranslation('editCourseForm', { keyPrefix: 'quickWidgetEditor' });

  return (
    <Box className="widget-type" sx={sxFlexColumnGap}>
      <MyTextField {...textFieldProps} name="attributes.widget_type" control={control} disabled label={t('type')} />
      <MyTextField {...numericalProps} name="attributes.weight" control={control} margin="normal" label={t('weight')} />
      <MyTextField {...textFieldProps} name="attributes.widget_code" control={control} label="widget code" />
    </Box>
  );
};

FormDefaultWidgetsAttributes.propTypes = {
  control: PropTypes.any
};

export default observer(FormDefaultWidgetsAttributes);

const sxFlexColumnGap = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px'
};
