import { useStore } from 'hooks';

export const DefaultBanner = () => {
  const store = useStore();
  const profession = store.profession.name;
  const professionLower = profession.toLowerCase();
  // PE/PO
  if (professionLower.includes('adv')) return '/profBanners/advocaten.jpg';
  if (professionLower.includes('nota')) return '/profBanners/notaris.jpg';
  if (professionLower.includes('fisc')) return '/profBanners/fiscalist.jpg';
  if (professionLower.includes('accou')) return '/profBanners/accountant.jpg';
  // CME
  if (professionLower.includes('fysio')) return '/profBanners/fysio.jpg';
  if (professionLower.includes('tandartsass')) return '/profBanners/ta_as.jpg';
  if (professionLower.includes('tandarts')) return '/profBanners/ta_mo.jpg';
  if (professionLower.includes('mondh')) return '/profBanners/ta_mo.jpg';
  if (professionLower.includes('apotheker')) return '/profBanners/apotheker.jpg';
  if (professionLower.includes('gehandicapten')) return '/profBanners/huisarts.jpg';
  if (professionLower.includes('huisarts')) return '/profBanners/huisarts.jpg';
  if (professionLower.includes('ouderen')) return '/profBanners/so_eme.jpg';
  if (professionLower.includes('verpleegkundig')) return '/profBanners/vs.jpg';
  if (professionLower.includes('consulent')) return '/profBanners/apotheker.jpg';
  return '/profBanners/elearn.jpg';
};
